import { Record } from "../fable-library-js.4.16.0/Types.js";
import { enum_type, bool_type, array_type, int64_type, option_type, list_type, int32_type, record_type, decimal_type, class_type, string_type } from "../fable-library-js.4.16.0/Reflection.js";

export class SalesInvoiceHeaderDto extends Record {
    constructor(DataAreaId, InvoiceNumber, InvoiceDate, TotalTaxAmount, TotalDiscountCustomerGroupCode, TotalChargeAmount, TotalDiscountAmount, InvoiceHeaderTaxAmount, TotalInvoiceAmount, InvoiceAddressCountryRegionISOCode, InvoiceAddressZipCode, InvoiceAddressStreetNumber, InvoiceAddressStreet, CurrencyCode, SalesOrderNumber, DeliveryTermsCode, ContactPersonId, SalesOrderResponsiblePersonnelNumber, PaymentTermsName, DeliveryModeCode, InvoiceCustomerAccountNumber, InvoiceAddressCity, InvoiceAddressState, InvoiceAddressCountryRegionId, CustomersOrderReference, SalesOrderOriginCode) {
        super();
        this.DataAreaId = DataAreaId;
        this.InvoiceNumber = InvoiceNumber;
        this.InvoiceDate = InvoiceDate;
        this.TotalTaxAmount = TotalTaxAmount;
        this.TotalDiscountCustomerGroupCode = TotalDiscountCustomerGroupCode;
        this.TotalChargeAmount = TotalChargeAmount;
        this.TotalDiscountAmount = TotalDiscountAmount;
        this.InvoiceHeaderTaxAmount = InvoiceHeaderTaxAmount;
        this.TotalInvoiceAmount = TotalInvoiceAmount;
        this.InvoiceAddressCountryRegionISOCode = InvoiceAddressCountryRegionISOCode;
        this.InvoiceAddressZipCode = InvoiceAddressZipCode;
        this.InvoiceAddressStreetNumber = InvoiceAddressStreetNumber;
        this.InvoiceAddressStreet = InvoiceAddressStreet;
        this.CurrencyCode = CurrencyCode;
        this.SalesOrderNumber = SalesOrderNumber;
        this.DeliveryTermsCode = DeliveryTermsCode;
        this.ContactPersonId = ContactPersonId;
        this.SalesOrderResponsiblePersonnelNumber = SalesOrderResponsiblePersonnelNumber;
        this.PaymentTermsName = PaymentTermsName;
        this.DeliveryModeCode = DeliveryModeCode;
        this.InvoiceCustomerAccountNumber = InvoiceCustomerAccountNumber;
        this.InvoiceAddressCity = InvoiceAddressCity;
        this.InvoiceAddressState = InvoiceAddressState;
        this.InvoiceAddressCountryRegionId = InvoiceAddressCountryRegionId;
        this.CustomersOrderReference = CustomersOrderReference;
        this.SalesOrderOriginCode = SalesOrderOriginCode;
    }
}

export function SalesInvoiceHeaderDto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.SalesInvoiceHeaderDto", [], SalesInvoiceHeaderDto, () => [["DataAreaId", string_type], ["InvoiceNumber", string_type], ["InvoiceDate", class_type("System.DateTimeOffset")], ["TotalTaxAmount", decimal_type], ["TotalDiscountCustomerGroupCode", decimal_type], ["TotalChargeAmount", decimal_type], ["TotalDiscountAmount", decimal_type], ["InvoiceHeaderTaxAmount", decimal_type], ["TotalInvoiceAmount", decimal_type], ["InvoiceAddressCountryRegionISOCode", string_type], ["InvoiceAddressZipCode", string_type], ["InvoiceAddressStreetNumber", string_type], ["InvoiceAddressStreet", string_type], ["CurrencyCode", string_type], ["SalesOrderNumber", string_type], ["DeliveryTermsCode", string_type], ["ContactPersonId", string_type], ["SalesOrderResponsiblePersonnelNumber", string_type], ["PaymentTermsName", string_type], ["DeliveryModeCode", string_type], ["InvoiceCustomerAccountNumber", string_type], ["InvoiceAddressCity", string_type], ["InvoiceAddressState", string_type], ["InvoiceAddressCountryRegionId", string_type], ["CustomersOrderReference", string_type], ["SalesOrderOriginCode", string_type]]);
}

export class SalesInvoiceLineDto extends Record {
    constructor(DataAreaId, InvoiceNumber, LineCreationSequenceNumber, InvoiceDate, LineTotalDiscountAmount, LineTotalTaxAmount, ConfirmedShippingDate, SalesPrice, LineTotalChargeAmount, InvoicedQuantity, LineAmount, DimensionNumber, InventorySiteId, ItemBatchNumber, ProductConfigurationId, OrderedInventoryStatusId, ProductSizeId, ProductColorId, ProductVersionId, ProductStyleId, InventoryWarehouseId, SalesUnitSymbol, ProductDescription, ProductNumber, ProductName) {
        super();
        this.DataAreaId = DataAreaId;
        this.InvoiceNumber = InvoiceNumber;
        this.LineCreationSequenceNumber = (LineCreationSequenceNumber | 0);
        this.InvoiceDate = InvoiceDate;
        this.LineTotalDiscountAmount = LineTotalDiscountAmount;
        this.LineTotalTaxAmount = LineTotalTaxAmount;
        this.ConfirmedShippingDate = ConfirmedShippingDate;
        this.SalesPrice = SalesPrice;
        this.LineTotalChargeAmount = LineTotalChargeAmount;
        this.InvoicedQuantity = InvoicedQuantity;
        this.LineAmount = LineAmount;
        this.DimensionNumber = DimensionNumber;
        this.InventorySiteId = InventorySiteId;
        this.ItemBatchNumber = ItemBatchNumber;
        this.ProductConfigurationId = ProductConfigurationId;
        this.OrderedInventoryStatusId = OrderedInventoryStatusId;
        this.ProductSizeId = ProductSizeId;
        this.ProductColorId = ProductColorId;
        this.ProductVersionId = ProductVersionId;
        this.ProductStyleId = ProductStyleId;
        this.InventoryWarehouseId = InventoryWarehouseId;
        this.SalesUnitSymbol = SalesUnitSymbol;
        this.ProductDescription = ProductDescription;
        this.ProductNumber = ProductNumber;
        this.ProductName = ProductName;
    }
}

export function SalesInvoiceLineDto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.SalesInvoiceLineDto", [], SalesInvoiceLineDto, () => [["DataAreaId", string_type], ["InvoiceNumber", string_type], ["LineCreationSequenceNumber", int32_type], ["InvoiceDate", class_type("System.DateTimeOffset")], ["LineTotalDiscountAmount", decimal_type], ["LineTotalTaxAmount", decimal_type], ["ConfirmedShippingDate", class_type("System.DateTimeOffset")], ["SalesPrice", decimal_type], ["LineTotalChargeAmount", decimal_type], ["InvoicedQuantity", decimal_type], ["LineAmount", decimal_type], ["DimensionNumber", string_type], ["InventorySiteId", string_type], ["ItemBatchNumber", string_type], ["ProductConfigurationId", string_type], ["OrderedInventoryStatusId", string_type], ["ProductSizeId", string_type], ["ProductColorId", string_type], ["ProductVersionId", string_type], ["ProductStyleId", string_type], ["InventoryWarehouseId", string_type], ["SalesUnitSymbol", string_type], ["ProductDescription", string_type], ["ProductNumber", string_type], ["ProductName", string_type]]);
}

export class SalesOrderHeaderV3Dto extends Record {
    constructor(DataAreaId, SalesOrderName, SalesOrderNumber, DeliveryAddressName, BaseDocumentType, ChargeCustomerGroupId, CurrencyCode, CustomerRequisitionNumber, DefaultLedgerDimensionDisplayValue, DefaultShippingSiteId, DefaultShippingWarehouseId, DeliveryAddressCity, DeliveryAddressCountryRegionId, DeliveryAddressCountryRegionISOCode, DeliveryAddressCountyId, DeliveryAddressLocationId, DeliveryAddressStateId, DeliveryAddressStreet, DeliveryAddressZipCode, DeliveryModeCode, DeliveryTermsCode, FormattedDelveryAddress, InvoiceCustomerAccountNumber, InvoiceType, OrderingCustomerAccountNumber, SalesOrderProcessingStatus, SalesOrderStatus) {
        super();
        this.DataAreaId = DataAreaId;
        this.SalesOrderName = SalesOrderName;
        this.SalesOrderNumber = SalesOrderNumber;
        this.DeliveryAddressName = DeliveryAddressName;
        this.BaseDocumentType = BaseDocumentType;
        this.ChargeCustomerGroupId = ChargeCustomerGroupId;
        this.CurrencyCode = CurrencyCode;
        this.CustomerRequisitionNumber = CustomerRequisitionNumber;
        this.DefaultLedgerDimensionDisplayValue = DefaultLedgerDimensionDisplayValue;
        this.DefaultShippingSiteId = DefaultShippingSiteId;
        this.DefaultShippingWarehouseId = DefaultShippingWarehouseId;
        this.DeliveryAddressCity = DeliveryAddressCity;
        this.DeliveryAddressCountryRegionId = DeliveryAddressCountryRegionId;
        this.DeliveryAddressCountryRegionISOCode = DeliveryAddressCountryRegionISOCode;
        this.DeliveryAddressCountyId = DeliveryAddressCountyId;
        this.DeliveryAddressLocationId = DeliveryAddressLocationId;
        this.DeliveryAddressStateId = DeliveryAddressStateId;
        this.DeliveryAddressStreet = DeliveryAddressStreet;
        this.DeliveryAddressZipCode = DeliveryAddressZipCode;
        this.DeliveryModeCode = DeliveryModeCode;
        this.DeliveryTermsCode = DeliveryTermsCode;
        this.FormattedDelveryAddress = FormattedDelveryAddress;
        this.InvoiceCustomerAccountNumber = InvoiceCustomerAccountNumber;
        this.InvoiceType = InvoiceType;
        this.OrderingCustomerAccountNumber = OrderingCustomerAccountNumber;
        this.SalesOrderProcessingStatus = SalesOrderProcessingStatus;
        this.SalesOrderStatus = SalesOrderStatus;
    }
}

export function SalesOrderHeaderV3Dto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.SalesOrderHeaderV3Dto", [], SalesOrderHeaderV3Dto, () => [["DataAreaId", string_type], ["SalesOrderName", string_type], ["SalesOrderNumber", string_type], ["DeliveryAddressName", string_type], ["BaseDocumentType", string_type], ["ChargeCustomerGroupId", string_type], ["CurrencyCode", string_type], ["CustomerRequisitionNumber", string_type], ["DefaultLedgerDimensionDisplayValue", string_type], ["DefaultShippingSiteId", string_type], ["DefaultShippingWarehouseId", string_type], ["DeliveryAddressCity", string_type], ["DeliveryAddressCountryRegionId", string_type], ["DeliveryAddressCountryRegionISOCode", string_type], ["DeliveryAddressCountyId", string_type], ["DeliveryAddressLocationId", string_type], ["DeliveryAddressStateId", string_type], ["DeliveryAddressStreet", string_type], ["DeliveryAddressZipCode", string_type], ["DeliveryModeCode", string_type], ["DeliveryTermsCode", string_type], ["FormattedDelveryAddress", string_type], ["InvoiceCustomerAccountNumber", string_type], ["InvoiceType", string_type], ["OrderingCustomerAccountNumber", string_type], ["SalesOrderProcessingStatus", string_type], ["SalesOrderStatus", string_type]]);
}

export class VendorSearchDto extends Record {
    constructor(DataAreaId, VendorAccountNumber, VendorOrganizationName) {
        super();
        this.DataAreaId = DataAreaId;
        this.VendorAccountNumber = VendorAccountNumber;
        this.VendorOrganizationName = VendorOrganizationName;
    }
}

export function VendorSearchDto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.VendorSearchDto", [], VendorSearchDto, () => [["DataAreaId", string_type], ["VendorAccountNumber", string_type], ["VendorOrganizationName", string_type]]);
}

export class CustomerSearchDto extends Record {
    constructor(DataAreaId, CustomerAccount, OrganizationName, CustomerGroupId) {
        super();
        this.DataAreaId = DataAreaId;
        this.CustomerAccount = CustomerAccount;
        this.OrganizationName = OrganizationName;
        this.CustomerGroupId = CustomerGroupId;
    }
}

export function CustomerSearchDto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.CustomerSearchDto", [], CustomerSearchDto, () => [["DataAreaId", string_type], ["CustomerAccount", string_type], ["OrganizationName", string_type], ["CustomerGroupId", string_type]]);
}

export class LegalEntityLookupDto extends Record {
    constructor(LegalEntityId, Name) {
        super();
        this.LegalEntityId = LegalEntityId;
        this.Name = Name;
    }
}

export function LegalEntityLookupDto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.LegalEntityLookupDto", [], LegalEntityLookupDto, () => [["LegalEntityId", string_type], ["Name", string_type]]);
}

export class D365UserImpersonationDto extends Record {
    constructor(PspFinanceUser, ImpersonatedUser) {
        super();
        this.PspFinanceUser = PspFinanceUser;
        this.ImpersonatedUser = ImpersonatedUser;
    }
}

export function D365UserImpersonationDto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.D365UserImpersonationDto", [], D365UserImpersonationDto, () => [["PspFinanceUser", string_type], ["ImpersonatedUser", string_type]]);
}

export class D365UserDto extends Record {
    constructor(Email, StoreNumbers, CustomerAccountNumbers, VendorAccountNumbers) {
        super();
        this.Email = Email;
        this.StoreNumbers = StoreNumbers;
        this.CustomerAccountNumbers = CustomerAccountNumbers;
        this.VendorAccountNumbers = VendorAccountNumbers;
    }
}

export function D365UserDto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.D365UserDto", [], D365UserDto, () => [["Email", string_type], ["StoreNumbers", list_type(string_type)], ["CustomerAccountNumbers", list_type(string_type)], ["VendorAccountNumbers", list_type(string_type)]]);
}

export class InvoiceSearchParametersDto extends Record {
    constructor(Search, FromDate, ToDate, Skip, Take) {
        super();
        this.Search = Search;
        this.FromDate = FromDate;
        this.ToDate = ToDate;
        this.Skip = (Skip | 0);
        this.Take = (Take | 0);
    }
}

export function InvoiceSearchParametersDto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.InvoiceSearchParametersDto", [], InvoiceSearchParametersDto, () => [["Search", string_type], ["FromDate", option_type(class_type("System.DateTime"))], ["ToDate", option_type(class_type("System.DateTime"))], ["Skip", int32_type], ["Take", int32_type]]);
}

export class InvoiceDataDto extends Record {
    constructor(SiteId, OperatingUnitName, FullPrimaryAddress) {
        super();
        this.SiteId = SiteId;
        this.OperatingUnitName = OperatingUnitName;
        this.FullPrimaryAddress = FullPrimaryAddress;
    }
}

export function InvoiceDataDto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.InvoiceDataDto", [], InvoiceDataDto, () => [["SiteId", string_type], ["OperatingUnitName", string_type], ["FullPrimaryAddress", string_type]]);
}

export class PurchaseOrderHeaderV2Dto extends Record {
    constructor(AccountingDate, DataAreaId, DefaultReceivingSiteId, DeliveryAddressDescription, DeliveryAddressName, DeliveryModeId, DeliveryTermsId, DocumentApprovalStatus, FormattedDeliveryAddress, InvoiceType, InvoiceVendorAccountNumber, OrderVendorAccountNumber, PaymentTermsName, PurchaseOrderHeaderCreationMethod, PurchaseOrderName, PurchaseOrderNumber, PurchaseOrderStatus, RequestedDeliveryDate, VendorPaymentMethodName) {
        super();
        this.AccountingDate = AccountingDate;
        this.DataAreaId = DataAreaId;
        this.DefaultReceivingSiteId = DefaultReceivingSiteId;
        this.DeliveryAddressDescription = DeliveryAddressDescription;
        this.DeliveryAddressName = DeliveryAddressName;
        this.DeliveryModeId = DeliveryModeId;
        this.DeliveryTermsId = DeliveryTermsId;
        this.DocumentApprovalStatus = DocumentApprovalStatus;
        this.FormattedDeliveryAddress = FormattedDeliveryAddress;
        this.InvoiceType = InvoiceType;
        this.InvoiceVendorAccountNumber = InvoiceVendorAccountNumber;
        this.OrderVendorAccountNumber = OrderVendorAccountNumber;
        this.PaymentTermsName = PaymentTermsName;
        this.PurchaseOrderHeaderCreationMethod = PurchaseOrderHeaderCreationMethod;
        this.PurchaseOrderName = PurchaseOrderName;
        this.PurchaseOrderNumber = PurchaseOrderNumber;
        this.PurchaseOrderStatus = PurchaseOrderStatus;
        this.RequestedDeliveryDate = RequestedDeliveryDate;
        this.VendorPaymentMethodName = VendorPaymentMethodName;
    }
}

export function PurchaseOrderHeaderV2Dto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.PurchaseOrderHeaderV2Dto", [], PurchaseOrderHeaderV2Dto, () => [["AccountingDate", class_type("System.DateTimeOffset")], ["DataAreaId", string_type], ["DefaultReceivingSiteId", string_type], ["DeliveryAddressDescription", string_type], ["DeliveryAddressName", string_type], ["DeliveryModeId", string_type], ["DeliveryTermsId", string_type], ["DocumentApprovalStatus", string_type], ["FormattedDeliveryAddress", string_type], ["InvoiceType", string_type], ["InvoiceVendorAccountNumber", string_type], ["OrderVendorAccountNumber", string_type], ["PaymentTermsName", string_type], ["PurchaseOrderHeaderCreationMethod", string_type], ["PurchaseOrderName", string_type], ["PurchaseOrderNumber", string_type], ["PurchaseOrderStatus", string_type], ["RequestedDeliveryDate", class_type("System.DateTimeOffset")], ["VendorPaymentMethodName", string_type]]);
}

export class POSearchParametersDto extends Record {
    constructor(Search, PurchaseOrderName, DefaultReceivingSiteId, FromDate, ToDate, Skip, Take) {
        super();
        this.Search = Search;
        this.PurchaseOrderName = PurchaseOrderName;
        this.DefaultReceivingSiteId = DefaultReceivingSiteId;
        this.FromDate = FromDate;
        this.ToDate = ToDate;
        this.Skip = (Skip | 0);
        this.Take = (Take | 0);
    }
}

export function POSearchParametersDto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.POSearchParametersDto", [], POSearchParametersDto, () => [["Search", string_type], ["PurchaseOrderName", string_type], ["DefaultReceivingSiteId", string_type], ["FromDate", option_type(class_type("System.DateTime"))], ["ToDate", option_type(class_type("System.DateTime"))], ["Skip", int32_type], ["Take", int32_type]]);
}

export class PurchaseOrderLineV2Dto extends Record {
    constructor(Barcode, DataAreaId, DeliveryAddressDescription, ExternalItemNumber, FixedAssetTransactionType, FormattedDelveryAddress, InventoryLotId, ItemNumber, LineAmount, LineDescription, LineNumber, OrderedPurchaseQuantity, ProcurementProductCategoryName, PurchaseOrderLineCreationMethod, PurchaseOrderLineStatus, PurchaseOrderNumber, PurchasePrice, PurchasePriceQuantity, PurchaseUnitSymbol, ReceivingSiteId, RequestedDeliveryDate, SalesTaxGroupCode, Tax1099StateAmount, UnitWeight) {
        super();
        this.Barcode = Barcode;
        this.DataAreaId = DataAreaId;
        this.DeliveryAddressDescription = DeliveryAddressDescription;
        this.ExternalItemNumber = ExternalItemNumber;
        this.FixedAssetTransactionType = FixedAssetTransactionType;
        this.FormattedDelveryAddress = FormattedDelveryAddress;
        this.InventoryLotId = InventoryLotId;
        this.ItemNumber = ItemNumber;
        this.LineAmount = LineAmount;
        this.LineDescription = LineDescription;
        this.LineNumber = (LineNumber | 0);
        this.OrderedPurchaseQuantity = OrderedPurchaseQuantity;
        this.ProcurementProductCategoryName = ProcurementProductCategoryName;
        this.PurchaseOrderLineCreationMethod = PurchaseOrderLineCreationMethod;
        this.PurchaseOrderLineStatus = PurchaseOrderLineStatus;
        this.PurchaseOrderNumber = PurchaseOrderNumber;
        this.PurchasePrice = PurchasePrice;
        this.PurchasePriceQuantity = PurchasePriceQuantity;
        this.PurchaseUnitSymbol = PurchaseUnitSymbol;
        this.ReceivingSiteId = ReceivingSiteId;
        this.RequestedDeliveryDate = RequestedDeliveryDate;
        this.SalesTaxGroupCode = SalesTaxGroupCode;
        this.Tax1099StateAmount = Tax1099StateAmount;
        this.UnitWeight = UnitWeight;
    }
}

export function PurchaseOrderLineV2Dto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.PurchaseOrderLineV2Dto", [], PurchaseOrderLineV2Dto, () => [["Barcode", string_type], ["DataAreaId", string_type], ["DeliveryAddressDescription", string_type], ["ExternalItemNumber", string_type], ["FixedAssetTransactionType", string_type], ["FormattedDelveryAddress", string_type], ["InventoryLotId", string_type], ["ItemNumber", string_type], ["LineAmount", decimal_type], ["LineDescription", string_type], ["LineNumber", int32_type], ["OrderedPurchaseQuantity", decimal_type], ["ProcurementProductCategoryName", string_type], ["PurchaseOrderLineCreationMethod", string_type], ["PurchaseOrderLineStatus", string_type], ["PurchaseOrderNumber", string_type], ["PurchasePrice", decimal_type], ["PurchasePriceQuantity", decimal_type], ["PurchaseUnitSymbol", string_type], ["ReceivingSiteId", string_type], ["RequestedDeliveryDate", class_type("System.DateTimeOffset")], ["SalesTaxGroupCode", string_type], ["Tax1099StateAmount", decimal_type], ["UnitWeight", decimal_type]]);
}

export class PurchaseOrderHeaderChargeDto extends Record {
    constructor(ChargeCategory, ChargeDescription, ChargeLineNumber, ChargePercentage, DataAreaId, ExternalChargeAmount, FixedChargeAmount, IsIntercompanyCharge, ProportionalChargeAmount, PurchaseChargeCode, PurchaseOrderNumber, SalesTaxGroupCode) {
        super();
        this.ChargeCategory = ChargeCategory;
        this.ChargeDescription = ChargeDescription;
        this.ChargeLineNumber = (ChargeLineNumber | 0);
        this.ChargePercentage = ChargePercentage;
        this.DataAreaId = DataAreaId;
        this.ExternalChargeAmount = ExternalChargeAmount;
        this.FixedChargeAmount = FixedChargeAmount;
        this.IsIntercompanyCharge = IsIntercompanyCharge;
        this.ProportionalChargeAmount = ProportionalChargeAmount;
        this.PurchaseChargeCode = PurchaseChargeCode;
        this.PurchaseOrderNumber = PurchaseOrderNumber;
        this.SalesTaxGroupCode = SalesTaxGroupCode;
    }
}

export function PurchaseOrderHeaderChargeDto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.PurchaseOrderHeaderChargeDto", [], PurchaseOrderHeaderChargeDto, () => [["ChargeCategory", string_type], ["ChargeDescription", string_type], ["ChargeLineNumber", int32_type], ["ChargePercentage", decimal_type], ["DataAreaId", string_type], ["ExternalChargeAmount", decimal_type], ["FixedChargeAmount", decimal_type], ["IsIntercompanyCharge", string_type], ["ProportionalChargeAmount", decimal_type], ["PurchaseChargeCode", string_type], ["PurchaseOrderNumber", string_type], ["SalesTaxGroupCode", string_type]]);
}

export class PurchaseOrderLineChargeV2Dto extends Record {
    constructor(ChargeCategory, ChargeDescription, ChargeLineNumber, ChargePercentage, DataAreaId, ExternalChargeAmount, FixedChargeAmount, IntercompanyChargePercentage, IsIntercompanyCharge, ProportionalChargeAmount, PurchaseChargeCode, PurchaseOrderLineNumber, PurchaseOrderNumber, SalesTaxGroupCode, UnitChargeAmount) {
        super();
        this.ChargeCategory = ChargeCategory;
        this.ChargeDescription = ChargeDescription;
        this.ChargeLineNumber = (ChargeLineNumber | 0);
        this.ChargePercentage = ChargePercentage;
        this.DataAreaId = DataAreaId;
        this.ExternalChargeAmount = ExternalChargeAmount;
        this.FixedChargeAmount = FixedChargeAmount;
        this.IntercompanyChargePercentage = IntercompanyChargePercentage;
        this.IsIntercompanyCharge = IsIntercompanyCharge;
        this.ProportionalChargeAmount = ProportionalChargeAmount;
        this.PurchaseChargeCode = PurchaseChargeCode;
        this.PurchaseOrderLineNumber = (PurchaseOrderLineNumber | 0);
        this.PurchaseOrderNumber = PurchaseOrderNumber;
        this.SalesTaxGroupCode = SalesTaxGroupCode;
        this.UnitChargeAmount = UnitChargeAmount;
    }
}

export function PurchaseOrderLineChargeV2Dto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.PurchaseOrderLineChargeV2Dto", [], PurchaseOrderLineChargeV2Dto, () => [["ChargeCategory", string_type], ["ChargeDescription", string_type], ["ChargeLineNumber", int32_type], ["ChargePercentage", decimal_type], ["DataAreaId", string_type], ["ExternalChargeAmount", decimal_type], ["FixedChargeAmount", decimal_type], ["IntercompanyChargePercentage", decimal_type], ["IsIntercompanyCharge", string_type], ["ProportionalChargeAmount", decimal_type], ["PurchaseChargeCode", string_type], ["PurchaseOrderLineNumber", int32_type], ["PurchaseOrderNumber", string_type], ["SalesTaxGroupCode", string_type], ["UnitChargeAmount", decimal_type]]);
}

export class VendorInvoiceHeaderDto extends Record {
    constructor(BankAccount, CashDiscount, CashDiscountDate, DataAreaId, Date$, DeliveryFreightChargeTerms, DeliveryName, DueDate, EndDateTime, FreightedBy, GSTInvoiceType, HeaderReference, InvoiceAccount, InvoiceDate, InvoiceNumber, InvoicePaymentReleaseDate, InvoiceReceivedDate, IsApproved, IsBatch, IsElectronicInvoiceForService, MethodOfPayment, PaymentSpecification, PurchaseOrderNumber, TotalDiscount, VendorAccount, VendorName) {
        super();
        this.BankAccount = BankAccount;
        this.CashDiscount = CashDiscount;
        this.CashDiscountDate = CashDiscountDate;
        this.DataAreaId = DataAreaId;
        this.Date = Date$;
        this.DeliveryFreightChargeTerms = DeliveryFreightChargeTerms;
        this.DeliveryName = DeliveryName;
        this.DueDate = DueDate;
        this.EndDateTime = EndDateTime;
        this.FreightedBy = FreightedBy;
        this.GSTInvoiceType = GSTInvoiceType;
        this.HeaderReference = HeaderReference;
        this.InvoiceAccount = InvoiceAccount;
        this.InvoiceDate = InvoiceDate;
        this.InvoiceNumber = InvoiceNumber;
        this.InvoicePaymentReleaseDate = InvoicePaymentReleaseDate;
        this.InvoiceReceivedDate = InvoiceReceivedDate;
        this.IsApproved = IsApproved;
        this.IsBatch = IsBatch;
        this.IsElectronicInvoiceForService = IsElectronicInvoiceForService;
        this.MethodOfPayment = MethodOfPayment;
        this.PaymentSpecification = PaymentSpecification;
        this.PurchaseOrderNumber = PurchaseOrderNumber;
        this.TotalDiscount = TotalDiscount;
        this.VendorAccount = VendorAccount;
        this.VendorName = VendorName;
    }
}

export function VendorInvoiceHeaderDto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.VendorInvoiceHeaderDto", [], VendorInvoiceHeaderDto, () => [["BankAccount", string_type], ["CashDiscount", decimal_type], ["CashDiscountDate", class_type("System.DateTimeOffset")], ["DataAreaId", string_type], ["Date", class_type("System.DateTimeOffset")], ["DeliveryFreightChargeTerms", string_type], ["DeliveryName", string_type], ["DueDate", class_type("System.DateTimeOffset")], ["EndDateTime", class_type("System.DateTimeOffset")], ["FreightedBy", string_type], ["GSTInvoiceType", string_type], ["HeaderReference", string_type], ["InvoiceAccount", string_type], ["InvoiceDate", class_type("System.DateTimeOffset")], ["InvoiceNumber", string_type], ["InvoicePaymentReleaseDate", class_type("System.DateTimeOffset")], ["InvoiceReceivedDate", class_type("System.DateTimeOffset")], ["IsApproved", string_type], ["IsBatch", string_type], ["IsElectronicInvoiceForService", string_type], ["MethodOfPayment", string_type], ["PaymentSpecification", string_type], ["PurchaseOrderNumber", string_type], ["TotalDiscount", decimal_type], ["VendorAccount", string_type], ["VendorName", string_type]]);
}

export class VendorInvoiceLineDto extends Record {
    constructor(Amount, DataAreaId, DeliveryName, DimensionNumber, Discount, DiscountPercent, HeaderReference, InventorySiteId, InventoryWarehouseId, InvoiceAccount, InvoiceLineNumber, ItemName, ItemNumber, LineNumber, LineType, NetAmount, OriginalDeliverRemainder, PriceUnit, ProcurementCategoryHierarchyName, ProcurementCategoryName, PurchaseOrder, PurchLineNumber, ReceiveNow, RemainAfter, RemainAfterInvent, RemainBefore, RemainBeforeInvent, RetainageAmount, RetainPercentage, UnitPrice, VendorAccount, VendorInvoiceLineReviewStatus) {
        super();
        this.Amount = Amount;
        this.DataAreaId = DataAreaId;
        this.DeliveryName = DeliveryName;
        this.DimensionNumber = DimensionNumber;
        this.Discount = Discount;
        this.DiscountPercent = DiscountPercent;
        this.HeaderReference = HeaderReference;
        this.InventorySiteId = InventorySiteId;
        this.InventoryWarehouseId = InventoryWarehouseId;
        this.InvoiceAccount = InvoiceAccount;
        this.InvoiceLineNumber = InvoiceLineNumber;
        this.ItemName = ItemName;
        this.ItemNumber = ItemNumber;
        this.LineNumber = LineNumber;
        this.LineType = LineType;
        this.NetAmount = NetAmount;
        this.OriginalDeliverRemainder = OriginalDeliverRemainder;
        this.PriceUnit = PriceUnit;
        this.ProcurementCategoryHierarchyName = ProcurementCategoryHierarchyName;
        this.ProcurementCategoryName = ProcurementCategoryName;
        this.PurchaseOrder = PurchaseOrder;
        this.PurchLineNumber = PurchLineNumber;
        this.ReceiveNow = ReceiveNow;
        this.RemainAfter = RemainAfter;
        this.RemainAfterInvent = RemainAfterInvent;
        this.RemainBefore = RemainBefore;
        this.RemainBeforeInvent = RemainBeforeInvent;
        this.RetainageAmount = RetainageAmount;
        this.RetainPercentage = RetainPercentage;
        this.UnitPrice = UnitPrice;
        this.VendorAccount = VendorAccount;
        this.VendorInvoiceLineReviewStatus = VendorInvoiceLineReviewStatus;
    }
}

export function VendorInvoiceLineDto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.VendorInvoiceLineDto", [], VendorInvoiceLineDto, () => [["Amount", decimal_type], ["DataAreaId", string_type], ["DeliveryName", string_type], ["DimensionNumber", string_type], ["Discount", decimal_type], ["DiscountPercent", decimal_type], ["HeaderReference", string_type], ["InventorySiteId", string_type], ["InventoryWarehouseId", string_type], ["InvoiceAccount", string_type], ["InvoiceLineNumber", decimal_type], ["ItemName", string_type], ["ItemNumber", string_type], ["LineNumber", decimal_type], ["LineType", string_type], ["NetAmount", decimal_type], ["OriginalDeliverRemainder", decimal_type], ["PriceUnit", decimal_type], ["ProcurementCategoryHierarchyName", string_type], ["ProcurementCategoryName", string_type], ["PurchaseOrder", string_type], ["PurchLineNumber", int64_type], ["ReceiveNow", decimal_type], ["RemainAfter", decimal_type], ["RemainAfterInvent", decimal_type], ["RemainBefore", decimal_type], ["RemainBeforeInvent", decimal_type], ["RetainageAmount", decimal_type], ["RetainPercentage", decimal_type], ["UnitPrice", decimal_type], ["VendorAccount", string_type], ["VendorInvoiceLineReviewStatus", string_type]]);
}

export class VendorInvoiceHeaderChargeDto extends Record {
    constructor(ChargeCategory, ChargeDescription, ChargeLineNumber, ChargePercentage, DataAreaId, ExternalChargeAmount, FixedChargeAmount, HeaderReference, IsIntercompanyCharge, ProportionalChargeAmount, PurchaseChargeCode, SalesTaxGroupCode, VendInvoiceInfoTableDataAreaId) {
        super();
        this.ChargeCategory = ChargeCategory;
        this.ChargeDescription = ChargeDescription;
        this.ChargeLineNumber = (ChargeLineNumber | 0);
        this.ChargePercentage = ChargePercentage;
        this.DataAreaId = DataAreaId;
        this.ExternalChargeAmount = ExternalChargeAmount;
        this.FixedChargeAmount = FixedChargeAmount;
        this.HeaderReference = HeaderReference;
        this.IsIntercompanyCharge = IsIntercompanyCharge;
        this.ProportionalChargeAmount = ProportionalChargeAmount;
        this.PurchaseChargeCode = PurchaseChargeCode;
        this.SalesTaxGroupCode = SalesTaxGroupCode;
        this.VendInvoiceInfoTableDataAreaId = VendInvoiceInfoTableDataAreaId;
    }
}

export function VendorInvoiceHeaderChargeDto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.VendorInvoiceHeaderChargeDto", [], VendorInvoiceHeaderChargeDto, () => [["ChargeCategory", string_type], ["ChargeDescription", string_type], ["ChargeLineNumber", int32_type], ["ChargePercentage", decimal_type], ["DataAreaId", string_type], ["ExternalChargeAmount", decimal_type], ["FixedChargeAmount", decimal_type], ["HeaderReference", string_type], ["IsIntercompanyCharge", string_type], ["ProportionalChargeAmount", decimal_type], ["PurchaseChargeCode", string_type], ["SalesTaxGroupCode", string_type], ["VendInvoiceInfoTableDataAreaId", string_type]]);
}

export class VendorInvoiceLineChargeDto extends Record {
    constructor(ChargeCategory, ChargeDescription, ChargeLineNumber, ChargePercentage, DataAreaId, ExternalChargeAmount, FixedChargeAmount, HeaderReference, IntercompanyChargePercentage, InvoiceLineNumber, IsIntercompanyCharge, ProportionalChargeAmount, PurchaseChargeCode, PurchaseNumber, UnitChargeAmount) {
        super();
        this.ChargeCategory = ChargeCategory;
        this.ChargeDescription = ChargeDescription;
        this.ChargeLineNumber = (ChargeLineNumber | 0);
        this.ChargePercentage = ChargePercentage;
        this.DataAreaId = DataAreaId;
        this.ExternalChargeAmount = ExternalChargeAmount;
        this.FixedChargeAmount = FixedChargeAmount;
        this.HeaderReference = HeaderReference;
        this.IntercompanyChargePercentage = IntercompanyChargePercentage;
        this.InvoiceLineNumber = InvoiceLineNumber;
        this.IsIntercompanyCharge = IsIntercompanyCharge;
        this.ProportionalChargeAmount = ProportionalChargeAmount;
        this.PurchaseChargeCode = PurchaseChargeCode;
        this.PurchaseNumber = PurchaseNumber;
        this.UnitChargeAmount = UnitChargeAmount;
    }
}

export function VendorInvoiceLineChargeDto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.VendorInvoiceLineChargeDto", [], VendorInvoiceLineChargeDto, () => [["ChargeCategory", string_type], ["ChargeDescription", string_type], ["ChargeLineNumber", int32_type], ["ChargePercentage", decimal_type], ["DataAreaId", string_type], ["ExternalChargeAmount", decimal_type], ["FixedChargeAmount", decimal_type], ["HeaderReference", string_type], ["IntercompanyChargePercentage", decimal_type], ["InvoiceLineNumber", decimal_type], ["IsIntercompanyCharge", string_type], ["ProportionalChargeAmount", decimal_type], ["PurchaseChargeCode", string_type], ["PurchaseNumber", string_type], ["UnitChargeAmount", decimal_type]]);
}

export class VendInvoiceJourBiEntityDto extends Record {
    constructor(InvoiceId, PurchId, DataAreaId, DocumentDate) {
        super();
        this.InvoiceId = InvoiceId;
        this.PurchId = PurchId;
        this.DataAreaId = DataAreaId;
        this.DocumentDate = DocumentDate;
    }
}

export function VendInvoiceJourBiEntityDto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.VendInvoiceJourBiEntityDto", [], VendInvoiceJourBiEntityDto, () => [["InvoiceId", string_type], ["PurchId", string_type], ["DataAreaId", string_type], ["DocumentDate", class_type("System.DateTimeOffset")]]);
}

export class VendTransBiEntityDto extends Record {
    constructor(PaymReference, PaymMode, PaymTermId, DataAreaId, Invoice, Voucher, TransType, TransDate, AmountCur, AmountMST, SettleAmountCur, SettleAmountMST, AccountNum, ThirdPartyBankAccountId, DocumentDate, DueDate, Txt, Closed, CreatedOn, SourceKey, OffsetRecid) {
        super();
        this.PaymReference = PaymReference;
        this.PaymMode = PaymMode;
        this.PaymTermId = PaymTermId;
        this.DataAreaId = DataAreaId;
        this.Invoice = Invoice;
        this.Voucher = Voucher;
        this.TransType = TransType;
        this.TransDate = TransDate;
        this.AmountCur = AmountCur;
        this.AmountMST = AmountMST;
        this.SettleAmountCur = SettleAmountCur;
        this.SettleAmountMST = SettleAmountMST;
        this.AccountNum = AccountNum;
        this.ThirdPartyBankAccountId = ThirdPartyBankAccountId;
        this.DocumentDate = DocumentDate;
        this.DueDate = DueDate;
        this.Txt = Txt;
        this.Closed = Closed;
        this.CreatedOn = CreatedOn;
        this.SourceKey = SourceKey;
        this.OffsetRecid = OffsetRecid;
    }
}

export function VendTransBiEntityDto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.VendTransBiEntityDto", [], VendTransBiEntityDto, () => [["PaymReference", string_type], ["PaymMode", string_type], ["PaymTermId", string_type], ["DataAreaId", string_type], ["Invoice", string_type], ["Voucher", string_type], ["TransType", string_type], ["TransDate", class_type("System.DateTimeOffset")], ["AmountCur", decimal_type], ["AmountMST", decimal_type], ["SettleAmountCur", decimal_type], ["SettleAmountMST", decimal_type], ["AccountNum", string_type], ["ThirdPartyBankAccountId", string_type], ["DocumentDate", class_type("System.DateTimeOffset")], ["DueDate", class_type("System.DateTimeOffset")], ["Txt", string_type], ["Closed", class_type("System.DateTimeOffset")], ["CreatedOn", class_type("System.DateTimeOffset")], ["SourceKey", int64_type], ["OffsetRecid", int64_type]]);
}

export class VendInvoiceTransBiEntityDto extends Record {
    constructor(CreatedOn, DataAreaId, ExternalItemId, InventDate, InventQty, InvoiceDate, InvoiceId, ItemId, LineAmount, LineNum, OrigPurchId, PriceUnit, PurchaseLineLineNumber, PurchID, PurchPrice, PurchUnit, Qty, SourceDocumentLine) {
        super();
        this.CreatedOn = CreatedOn;
        this.DataAreaId = DataAreaId;
        this.ExternalItemId = ExternalItemId;
        this.InventDate = InventDate;
        this.InventQty = InventQty;
        this.InvoiceDate = InvoiceDate;
        this.InvoiceId = InvoiceId;
        this.ItemId = ItemId;
        this.LineAmount = LineAmount;
        this.LineNum = LineNum;
        this.OrigPurchId = OrigPurchId;
        this.PriceUnit = PriceUnit;
        this.PurchaseLineLineNumber = PurchaseLineLineNumber;
        this.PurchID = PurchID;
        this.PurchPrice = PurchPrice;
        this.PurchUnit = PurchUnit;
        this.Qty = Qty;
        this.SourceDocumentLine = SourceDocumentLine;
    }
}

export function VendInvoiceTransBiEntityDto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.VendInvoiceTransBiEntityDto", [], VendInvoiceTransBiEntityDto, () => [["CreatedOn", class_type("System.DateTimeOffset")], ["DataAreaId", string_type], ["ExternalItemId", string_type], ["InventDate", class_type("System.DateTimeOffset")], ["InventQty", decimal_type], ["InvoiceDate", class_type("System.DateTimeOffset")], ["InvoiceId", string_type], ["ItemId", string_type], ["LineAmount", decimal_type], ["LineNum", decimal_type], ["OrigPurchId", string_type], ["PriceUnit", decimal_type], ["PurchaseLineLineNumber", int64_type], ["PurchID", string_type], ["PurchPrice", decimal_type], ["PurchUnit", string_type], ["Qty", decimal_type], ["SourceDocumentLine", int64_type]]);
}

export class ProductReceiptLineV2Dto extends Record {
    constructor(DataAreaId, ItemNumber, LineNumber, OrderedPurchaseQuantity, ProductReceiptNumber, PurchaseOrderLineNumber, PurchaseOrderNumber, ReceivedInventoryQuantity, ReceivedPurchaseQuantity, ReceivingSiteId, RemainingInventoryQuantity, RemainingPurchaseQuantity) {
        super();
        this.DataAreaId = DataAreaId;
        this.ItemNumber = ItemNumber;
        this.LineNumber = LineNumber;
        this.OrderedPurchaseQuantity = OrderedPurchaseQuantity;
        this.ProductReceiptNumber = ProductReceiptNumber;
        this.PurchaseOrderLineNumber = PurchaseOrderLineNumber;
        this.PurchaseOrderNumber = PurchaseOrderNumber;
        this.ReceivedInventoryQuantity = ReceivedInventoryQuantity;
        this.ReceivedPurchaseQuantity = ReceivedPurchaseQuantity;
        this.ReceivingSiteId = ReceivingSiteId;
        this.RemainingInventoryQuantity = RemainingInventoryQuantity;
        this.RemainingPurchaseQuantity = RemainingPurchaseQuantity;
    }
}

export function ProductReceiptLineV2Dto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.ProductReceiptLineV2Dto", [], ProductReceiptLineV2Dto, () => [["DataAreaId", string_type], ["ItemNumber", string_type], ["LineNumber", decimal_type], ["OrderedPurchaseQuantity", decimal_type], ["ProductReceiptNumber", string_type], ["PurchaseOrderLineNumber", int64_type], ["PurchaseOrderNumber", string_type], ["ReceivedInventoryQuantity", decimal_type], ["ReceivedPurchaseQuantity", decimal_type], ["ReceivingSiteId", string_type], ["RemainingInventoryQuantity", decimal_type], ["RemainingPurchaseQuantity", decimal_type]]);
}

export class PurchaseOrderConfirmationHeaderDto extends Record {
    constructor(DataAreaId, InvoiceVendorAccountNumber, OrderVendorAccountNumber, PurchaseOrderNumber, TotalChargeAmount, TotalConfirmedAmount, TotalDiscountAmount) {
        super();
        this.DataAreaId = DataAreaId;
        this.InvoiceVendorAccountNumber = InvoiceVendorAccountNumber;
        this.OrderVendorAccountNumber = OrderVendorAccountNumber;
        this.PurchaseOrderNumber = PurchaseOrderNumber;
        this.TotalChargeAmount = TotalChargeAmount;
        this.TotalConfirmedAmount = TotalConfirmedAmount;
        this.TotalDiscountAmount = TotalDiscountAmount;
    }
}

export function PurchaseOrderConfirmationHeaderDto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.PurchaseOrderConfirmationHeaderDto", [], PurchaseOrderConfirmationHeaderDto, () => [["DataAreaId", string_type], ["InvoiceVendorAccountNumber", string_type], ["OrderVendorAccountNumber", string_type], ["PurchaseOrderNumber", string_type], ["TotalChargeAmount", decimal_type], ["TotalConfirmedAmount", decimal_type], ["TotalDiscountAmount", decimal_type]]);
}

export class BusinessDocumentMarkupTransactionDto extends Record {
    constructor(CalculatedAmount, DataAreaId, LineNum, MarkupCode, MarkupTransRecId, MarkupTransTableId, TransRecId, TransTableId) {
        super();
        this.CalculatedAmount = CalculatedAmount;
        this.DataAreaId = DataAreaId;
        this.LineNum = LineNum;
        this.MarkupCode = MarkupCode;
        this.MarkupTransRecId = MarkupTransRecId;
        this.MarkupTransTableId = (MarkupTransTableId | 0);
        this.TransRecId = TransRecId;
        this.TransTableId = (TransTableId | 0);
    }
}

export function BusinessDocumentMarkupTransactionDto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.BusinessDocumentMarkupTransactionDto", [], BusinessDocumentMarkupTransactionDto, () => [["CalculatedAmount", decimal_type], ["DataAreaId", string_type], ["LineNum", decimal_type], ["MarkupCode", string_type], ["MarkupTransRecId", int64_type], ["MarkupTransTableId", int32_type], ["TransRecId", int64_type], ["TransTableId", int32_type]]);
}

export class PODataResultDto extends Record {
    constructor(PurchaseOrderHeader, PurchaseOrderLines, PurchaseOrderHeaderCharges, PurchaseOrderLineCharges, VendInvoiceJourBiEntity, VendTransBiEntities, VendInvoiceTransBiEntities, ProductReceiptLines, ConfirmationHeader, MarkupTransactions) {
        super();
        this.PurchaseOrderHeader = PurchaseOrderHeader;
        this.PurchaseOrderLines = PurchaseOrderLines;
        this.PurchaseOrderHeaderCharges = PurchaseOrderHeaderCharges;
        this.PurchaseOrderLineCharges = PurchaseOrderLineCharges;
        this.VendInvoiceJourBiEntity = VendInvoiceJourBiEntity;
        this.VendTransBiEntities = VendTransBiEntities;
        this.VendInvoiceTransBiEntities = VendInvoiceTransBiEntities;
        this.ProductReceiptLines = ProductReceiptLines;
        this.ConfirmationHeader = ConfirmationHeader;
        this.MarkupTransactions = MarkupTransactions;
    }
}

export function PODataResultDto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.PODataResultDto", [], PODataResultDto, () => [["PurchaseOrderHeader", PurchaseOrderHeaderV2Dto_$reflection()], ["PurchaseOrderLines", array_type(PurchaseOrderLineV2Dto_$reflection())], ["PurchaseOrderHeaderCharges", array_type(PurchaseOrderHeaderChargeDto_$reflection())], ["PurchaseOrderLineCharges", array_type(PurchaseOrderLineChargeV2Dto_$reflection())], ["VendInvoiceJourBiEntity", option_type(VendInvoiceJourBiEntityDto_$reflection())], ["VendTransBiEntities", array_type(VendTransBiEntityDto_$reflection())], ["VendInvoiceTransBiEntities", array_type(VendInvoiceTransBiEntityDto_$reflection())], ["ProductReceiptLines", array_type(ProductReceiptLineV2Dto_$reflection())], ["ConfirmationHeader", option_type(PurchaseOrderConfirmationHeaderDto_$reflection())], ["MarkupTransactions", array_type(BusinessDocumentMarkupTransactionDto_$reflection())]]);
}

export class VendTransSearchParametersDto extends Record {
    constructor(PaymentRef, PaymentRefExactMatch, Invoice, VendorAccount, SourceKeys, FromDate, ToDate, Skip, Take, ApplyPaymentFilter, IncludeSettlements, TransStatus) {
        super();
        this.PaymentRef = PaymentRef;
        this.PaymentRefExactMatch = PaymentRefExactMatch;
        this.Invoice = Invoice;
        this.VendorAccount = VendorAccount;
        this.SourceKeys = SourceKeys;
        this.FromDate = FromDate;
        this.ToDate = ToDate;
        this.Skip = (Skip | 0);
        this.Take = (Take | 0);
        this.ApplyPaymentFilter = ApplyPaymentFilter;
        this.IncludeSettlements = IncludeSettlements;
        this.TransStatus = (TransStatus | 0);
    }
}

export function VendTransSearchParametersDto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.VendTransSearchParametersDto", [], VendTransSearchParametersDto, () => [["PaymentRef", string_type], ["PaymentRefExactMatch", bool_type], ["Invoice", string_type], ["VendorAccount", string_type], ["SourceKeys", list_type(int64_type)], ["FromDate", option_type(class_type("System.DateTime"))], ["ToDate", option_type(class_type("System.DateTime"))], ["Skip", int32_type], ["Take", int32_type], ["ApplyPaymentFilter", bool_type], ["IncludeSettlements", bool_type], ["TransStatus", enum_type("pogona_vendor_api_lib.D365Types.TransStatusDto", int32_type, [["All", 0], ["Open", 1], ["Closed", 2]])]]);
}

export class CustTransSearchParametersDto extends Record {
    constructor(PaymentRef, PaymentRefExactMatch, Invoice, CustomerAccount, SourceKeys, FromDate, ToDate, Skip, Take, ApplyPaymentFilter, IncludeSettlements, TransStatus) {
        super();
        this.PaymentRef = PaymentRef;
        this.PaymentRefExactMatch = PaymentRefExactMatch;
        this.Invoice = Invoice;
        this.CustomerAccount = CustomerAccount;
        this.SourceKeys = SourceKeys;
        this.FromDate = FromDate;
        this.ToDate = ToDate;
        this.Skip = (Skip | 0);
        this.Take = (Take | 0);
        this.ApplyPaymentFilter = ApplyPaymentFilter;
        this.IncludeSettlements = IncludeSettlements;
        this.TransStatus = (TransStatus | 0);
    }
}

export function CustTransSearchParametersDto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.CustTransSearchParametersDto", [], CustTransSearchParametersDto, () => [["PaymentRef", string_type], ["PaymentRefExactMatch", bool_type], ["Invoice", string_type], ["CustomerAccount", string_type], ["SourceKeys", list_type(int64_type)], ["FromDate", option_type(class_type("System.DateTime"))], ["ToDate", option_type(class_type("System.DateTime"))], ["Skip", int32_type], ["Take", int32_type], ["ApplyPaymentFilter", bool_type], ["IncludeSettlements", bool_type], ["TransStatus", enum_type("pogona_vendor_api_lib.D365Types.TransStatusDto", int32_type, [["All", 0], ["Open", 1], ["Closed", 2]])]]);
}

export class CustTransBiEntityDto extends Record {
    constructor(PaymReference, PaymMode, PaymTermId, DataAreaId, Invoice, Voucher, TransType, TransDate, AmountCur, AmountMST, SettleAmountCur, SettleAmountMST, AccountNum, ThirdPartyBankAccountId, DocumentDate, DueDate, Txt, Closed, CreatedOn, SourceKey, OffsetRecid) {
        super();
        this.PaymReference = PaymReference;
        this.PaymMode = PaymMode;
        this.PaymTermId = PaymTermId;
        this.DataAreaId = DataAreaId;
        this.Invoice = Invoice;
        this.Voucher = Voucher;
        this.TransType = TransType;
        this.TransDate = TransDate;
        this.AmountCur = AmountCur;
        this.AmountMST = AmountMST;
        this.SettleAmountCur = SettleAmountCur;
        this.SettleAmountMST = SettleAmountMST;
        this.AccountNum = AccountNum;
        this.ThirdPartyBankAccountId = ThirdPartyBankAccountId;
        this.DocumentDate = DocumentDate;
        this.DueDate = DueDate;
        this.Txt = Txt;
        this.Closed = Closed;
        this.CreatedOn = CreatedOn;
        this.SourceKey = SourceKey;
        this.OffsetRecid = OffsetRecid;
    }
}

export function CustTransBiEntityDto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.CustTransBiEntityDto", [], CustTransBiEntityDto, () => [["PaymReference", string_type], ["PaymMode", string_type], ["PaymTermId", string_type], ["DataAreaId", string_type], ["Invoice", string_type], ["Voucher", string_type], ["TransType", string_type], ["TransDate", class_type("System.DateTimeOffset")], ["AmountCur", decimal_type], ["AmountMST", decimal_type], ["SettleAmountCur", decimal_type], ["SettleAmountMST", decimal_type], ["AccountNum", string_type], ["ThirdPartyBankAccountId", string_type], ["DocumentDate", class_type("System.DateTimeOffset")], ["DueDate", class_type("System.DateTimeOffset")], ["Txt", string_type], ["Closed", class_type("System.DateTimeOffset")], ["CreatedOn", class_type("System.DateTimeOffset")], ["SourceKey", int64_type], ["OffsetRecid", int64_type]]);
}

export class VendSettlementSearchParametersDto extends Record {
    constructor(SettlementVouchers, OffsetTransVouchers, ExcludeOffsetTransVouchers, SourceKeys, DataAreaIds, Skip, Take) {
        super();
        this.SettlementVouchers = SettlementVouchers;
        this.OffsetTransVouchers = OffsetTransVouchers;
        this.ExcludeOffsetTransVouchers = ExcludeOffsetTransVouchers;
        this.SourceKeys = SourceKeys;
        this.DataAreaIds = DataAreaIds;
        this.Skip = (Skip | 0);
        this.Take = (Take | 0);
    }
}

export function VendSettlementSearchParametersDto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.VendSettlementSearchParametersDto", [], VendSettlementSearchParametersDto, () => [["SettlementVouchers", list_type(string_type)], ["OffsetTransVouchers", list_type(string_type)], ["ExcludeOffsetTransVouchers", list_type(string_type)], ["SourceKeys", list_type(int64_type)], ["DataAreaIds", list_type(string_type)], ["Skip", int32_type], ["Take", int32_type]]);
}

export class VendSettlementBiEntityDto extends Record {
    constructor(DataAreaId, AccountNum, SettleAmountCur, OffsetTransVoucher, SettlementVoucher, TransRecId, TransType, SourceKey, OffsetRecid, TransDate, ClosedDate, DueDate) {
        super();
        this.DataAreaId = DataAreaId;
        this.AccountNum = AccountNum;
        this.SettleAmountCur = SettleAmountCur;
        this.OffsetTransVoucher = OffsetTransVoucher;
        this.SettlementVoucher = SettlementVoucher;
        this.TransRecId = TransRecId;
        this.TransType = TransType;
        this.SourceKey = SourceKey;
        this.OffsetRecid = OffsetRecid;
        this.TransDate = TransDate;
        this.ClosedDate = ClosedDate;
        this.DueDate = DueDate;
    }
}

export function VendSettlementBiEntityDto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.VendSettlementBiEntityDto", [], VendSettlementBiEntityDto, () => [["DataAreaId", string_type], ["AccountNum", string_type], ["SettleAmountCur", decimal_type], ["OffsetTransVoucher", string_type], ["SettlementVoucher", string_type], ["TransRecId", int64_type], ["TransType", string_type], ["SourceKey", int64_type], ["OffsetRecid", int64_type], ["TransDate", class_type("System.DateTimeOffset")], ["ClosedDate", class_type("System.DateTimeOffset")], ["DueDate", class_type("System.DateTimeOffset")]]);
}

export class CustSettlementSearchParametersDto extends Record {
    constructor(SettlementVouchers, OffsetTransVouchers, ExcludeOffsetTransVouchers, SourceKeys, DataAreaIds, Skip, Take) {
        super();
        this.SettlementVouchers = SettlementVouchers;
        this.OffsetTransVouchers = OffsetTransVouchers;
        this.ExcludeOffsetTransVouchers = ExcludeOffsetTransVouchers;
        this.SourceKeys = SourceKeys;
        this.DataAreaIds = DataAreaIds;
        this.Skip = (Skip | 0);
        this.Take = (Take | 0);
    }
}

export function CustSettlementSearchParametersDto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.CustSettlementSearchParametersDto", [], CustSettlementSearchParametersDto, () => [["SettlementVouchers", list_type(string_type)], ["OffsetTransVouchers", list_type(string_type)], ["ExcludeOffsetTransVouchers", list_type(string_type)], ["SourceKeys", list_type(int64_type)], ["DataAreaIds", list_type(string_type)], ["Skip", int32_type], ["Take", int32_type]]);
}

export class CustSettlementBiEntityDto extends Record {
    constructor(DataAreaId, AccountNum, SettleAmountCur, OffsetTransVoucher, SettlementVoucher, TransRecId, TransType, SourceKey, OffsetRecid, TransDate, ClosedDate, DueDate) {
        super();
        this.DataAreaId = DataAreaId;
        this.AccountNum = AccountNum;
        this.SettleAmountCur = SettleAmountCur;
        this.OffsetTransVoucher = OffsetTransVoucher;
        this.SettlementVoucher = SettlementVoucher;
        this.TransRecId = TransRecId;
        this.TransType = TransType;
        this.SourceKey = SourceKey;
        this.OffsetRecid = OffsetRecid;
        this.TransDate = TransDate;
        this.ClosedDate = ClosedDate;
        this.DueDate = DueDate;
    }
}

export function CustSettlementBiEntityDto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.CustSettlementBiEntityDto", [], CustSettlementBiEntityDto, () => [["DataAreaId", string_type], ["AccountNum", string_type], ["SettleAmountCur", decimal_type], ["OffsetTransVoucher", string_type], ["SettlementVoucher", string_type], ["TransRecId", int64_type], ["TransType", string_type], ["SourceKey", int64_type], ["OffsetRecid", int64_type], ["TransDate", class_type("System.DateTimeOffset")], ["ClosedDate", class_type("System.DateTimeOffset")], ["DueDate", class_type("System.DateTimeOffset")]]);
}

export class VendTransSettlementResultDto extends Record {
    constructor(Settlements, Transactions) {
        super();
        this.Settlements = Settlements;
        this.Transactions = Transactions;
    }
}

export function VendTransSettlementResultDto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.VendTransSettlementResultDto", [], VendTransSettlementResultDto, () => [["Settlements", list_type(VendSettlementBiEntityDto_$reflection())], ["Transactions", list_type(VendTransBiEntityDto_$reflection())]]);
}

export class CustTransSettlementResultDto extends Record {
    constructor(Settlements, Transactions) {
        super();
        this.Settlements = Settlements;
        this.Transactions = Transactions;
    }
}

export function CustTransSettlementResultDto_$reflection() {
    return record_type("pogona_vendor_api_lib.D365Types.CustTransSettlementResultDto", [], CustTransSettlementResultDto, () => [["Settlements", list_type(CustSettlementBiEntityDto_$reflection())], ["Transactions", list_type(CustTransBiEntityDto_$reflection())]]);
}

