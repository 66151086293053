<template>
  <core-view-template :title="title">
    <div>
      <show-impersonation />
      <h3>Transactions</h3>
      <VendTransGrid
        :headers="transHeaders"
        :vendTrans="transItems"
        :loading="loading"
        :hideDefaultFooter="true"
        :page="1"
        :isServerSide="false"
        :items-per-page="-1"
      ></VendTransGrid>
    </div>
  </core-view-template>
</template>
<script setup lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import { useVpFinanceStore } from '@/stores/vpfinancestore';
import VendTransGrid from '@/components/vendorportal/VendTransGrid.vue';
import { useRoute } from 'vue-router/composables';
import ShowImpersonation from '@/components/vendorportal/ShowImpersonation.vue';
import { useTransGridShared } from '@/composables/TransGridShared';
defineComponent({
  name: 'VendTransPayRefDetail',
});
const gridShared = useTransGridShared();
const transHeaders = [
  gridShared.dataAreaIdHeader,
  gridShared.vendAcctHeader,
  gridShared.paymReferenceHeader,
  gridShared.paymModeHeader,
  gridShared.sortable(gridShared.invoiceHeader),
  gridShared.documentDateHeader,
  gridShared.closedHeader,
  gridShared.sortable(gridShared.settleAmountCurHeader),
  gridShared.transTypeHeader,
  gridShared.dueDateHeader,
  gridShared.paymTermIdHeader,
  gridShared.txtHeader,
];
const route = useRoute();
const vpFinanceStore = useVpFinanceStore();
const loading = ref(false);
const title = computed(() => {
  return 'Payment Detail';
});
const transItems = computed(() => {
  if (vpFinanceStore.vendSettlementResult) {
    const items = vpFinanceStore.vendSettlementResult.Transactions.map(x => {
      const settlement = vpFinanceStore.vendSettlementResult.Settlements.find(
        y => y.TransRecId === x.SourceKey,
      );
      const settleAmount = settlement ? settlement.SettleAmountCur : x.SettleAmountCur;
      const invoice = (() => {
        switch (x.TransType) {
          case 'CashDiscount': {
            const parent = vpFinanceStore.vendSettlementResult.Transactions.find(
              y => y.SourceKey === x.OffsetRecid,
            );
            return parent ? parent.Invoice : x.Invoice;
          }
          default:
            return x.Invoice;
        }
      })();
      return {
        ...x,
        SettleAmountCur: settleAmount,
        Invoice: invoice,
      };
    });
    return items;
  } else {
    return [];
  }
});
function getRouteValue(routeValue) {
  if (routeValue) {
    return routeValue === '-' ? '' : decodeURIComponent(routeValue);
  } else {
    return '';
  }
}
const dataAreaIdRoute = computed(() => {
  return getRouteValue(route.params.dataAreaId);
});
const voucherRoute = computed(() => {
  return getRouteValue(route.params.voucher);
});
function loadVendTrans() {
  if (dataAreaIdRoute.value !== 'undefined') {
    loading.value = true;
    vpFinanceStore
      .getVendSettlementsForTrans(voucherRoute.value, dataAreaIdRoute.value)
      .finally(() => {
        loading.value = false;
      });
  }
}
watch(
  [dataAreaIdRoute, voucherRoute],
  () => {
    loadVendTrans();
  },
  { immediate: true },
);
</script>
